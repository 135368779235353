<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("classroomTeacher.title") }}
          </template>
          <template v-slot:body>
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th class="text-left">
                  {{ $t("studentGroups.list.name") }}
                </th>
                <th class="text-left">
                  {{ $t("studentGroups.list.level") }}
                </th>
                <th class="text-left">
                  {{ $t("studentGroups.list.year") }}
                </th>
                <th class="text-left">
                  {{ $t("studentGroups.list.shift") }}
                </th>
                <th class="text-left"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in groups" :key="item.id">
                <td>{{ item.groupName }}</td>
                <td>{{ item.level }}</td>
                <td>{{ item.educationYearString }}</td>
                <td>{{ shiftVal(item.shift) }}</td>
                <td>
                  <div class="dropdown dropdown-inline mr-4">
                    <button class="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                      ...
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item" href="#" @click="openStudents(item)">{{ $t("studentsList.title") }}</a>
                      <a class="dropdown-item" href="#" @click="openGroupSubjects(item)">{{
                          $t("groupSubjects.title")
                        }}</a>
                      <a class="dropdown-item" href="#" @click="openTimeTable(item)">{{ $t("timeTable.title") }}</a>
                      <!--                  <a class="dropdown-item" href="#" @click="openGradeBook(item)">{{ $t("gradeBook.title") }}</a>-->
                    </div>
                  </div>

                  <div class="dropdown dropdown-inline mr-4">
                    <button class="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButtonReports"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                      Отчет
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item" href="#" @click="openQuarterMarks(item, 1)">I четверть</a>
                      <a class="dropdown-item" href="#" @click="openQuarterMarks(item, 2)">II четверть</a>
                      <a class="dropdown-item" href="#" @click="openQuarterMarks(item, 3)">III четверть</a>
                      <a class="dropdown-item" href="#" @click="openQuarterMarks(item, 4)">IV четверть</a>
                      <a class="dropdown-item" href="#" @click="openQuarterMarks(item, 5)">Годовой</a>
                      <a class="dropdown-item" href="#" @click="openGroupInfo(item)">Детали</a>
                      <router-link :to="{ name: 'GroupJournalExport', params: { groupId: item.id }}" class="dropdown-item">
                        Журнал
                      </router-link>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";

export default {
  name: "classroomTeacherStudentGroups",
  components: {
    KTPortlet
  },
  data() {
    return {
      groups: null
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    reloadData() {
      ApiService.querySecured("studentGroups/classroomTeacher", {}).then(({data}) => {
        this.groups = data.list;
      }).catch(({response}) => {
        console.log(response);
      });
    },
    cleanModel() {
      this.form = {};
    },
    openStudents(group) {
      this.$router.push({name: "students", params: {id: group.id}});
    },
    openGroupSubjects(group) {
      this.$router.push({name: "groupSubjects", params: {id: group.id}});
    },
    openTimeTable(group) {
      this.$router.push({name: "timeTableForGroup", params: {id: group.id}});
    },
    openGradeBook(group) {
      this.$router.push({name: "groupGradeBook", params: {groupId: group.id}});
    },
    openQuarterMarks(group, quarter) {
      this.$router.push({name: "quarterMarks", params: {groupId: group.id, quarter: quarter}});
    },
    openGroupInfo(group) {
      this.$router.push({name: "groupInfo", params: {groupId: group.id}});
    },
    shiftVal(shift) {
      let shiftVal = '';
      switch (shift) {
        case 1:
          shiftVal = 'Первая';
          break;
        case 2:
          shiftVal = 'Вторая';
          break;
        case 3:
          shiftVal = 'Третья';
          break;
      }

      return shiftVal;
    }
  }
};
</script>
